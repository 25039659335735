import { DoubleBarChart } from "../View/SubTopic/Charts/DoubleBarChart";
import { TwoColStackGraph } from "../View/SubTopic/Charts/TwoColStackGraph";
import { OneRowStackGraph } from "../View/SubTopic/Charts/OneRowStackGraph";
import { SingleBarChart } from "../View/SubTopic/Charts/SingleBarChart";
import { PlainSingleBarChart } from '../View/SubTopic/Charts/PlainSingleBarChart';
import { PlainOneRowStackGraph } from "../View/SubTopic/Charts/PlainOneRowStackGraph";
import { TwoColBarChart } from "../View/SubTopic/Charts/TwoColBarChart";
import { DataTable } from "../View/SubTopic/Tables/DataTable";
import { OneRowTwoColBarChart } from "../View/SubTopic/Charts/OneRowTwoColBarChart";
import { MeanOneRowStackGraph } from "../View/SubTopic/Charts/MeanOneRowStackGraph";
import { ToggleOneRowStackGraphTable } from "../View/SubTopic/Charts/ToggleOneRowStackGraphTable";
import { OneRowStackGraphLive } from "../View/SubTopic/Charts/OneRowStackGraphLive";
import { ToggleTableView } from "../View/SubTopic/Tables/ToggleTableView";
import { OneRowTwoColBarChartNoToggle } from "../View/SubTopic/Charts/OneRowTwoColBarChartNoToggle";
import { TogglePlainSingleBarChart } from "../View/SubTopic/Charts/TogglePlainSingleBarChart";
import { BarWithStackChart } from "../View/SubTopic/Charts/BarWithStackChart";
import { SingleDataTable } from "../View/SubTopic/Tables/SingleDataTable";
import { MultiToggleTableView } from "../View/SubTopic/Tables/MultiToggleTableView";
import { BarWithToggeleStackChart } from "../View/SubTopic/Charts/BarWithToggeleStackChart";
import { CustomInfo } from "../View/SubTopic/Charts/CustomInfo";
import { ToggleChartTableView } from "../View/SubTopic/Tables/CommonTabularComponents/ToggleChartTableView";
import { ToggleMeanOneRowStackGraph } from "../View/SubTopic/Charts/ToggleMeanOneRowStackGraph";
import { SingleBarChartWithLives } from "../View/SubTopic/Charts/SingleBarChartWithLives";
import { VerticlePlainSingleBarChart } from "../View/SubTopic/Charts/VerticlePlainSingleBarChart";
import { VerticleTwoViewPlainSingleBarChart } from "../View/SubTopic/Charts/VerticleTwoViewPlainSingleBarChart";

export const CHARTS_MAPPING = [
    {
        "subtopic": 1,
        "cards": [
            {
                "chartId": 1,
                "component": DoubleBarChart,
            },
            {
                "chartId": 2,
                "component": TwoColStackGraph
            },
            {
                "chartId": 3,
                "component": TwoColStackGraph
            },
            {
                "chartId": 4,
                "component": OneRowStackGraph
            },
            {
                "chartId": 5,
                "component": OneRowStackGraph
            },
            {
                "chartId": 6,
                "component": SingleBarChart
            },
            {
                "chartId": 7,
                "component": MeanOneRowStackGraph,//PlainOneRowStackGraph
            },
            {
                "chartId": 8,
                "component": OneRowStackGraph
            },
            {
                "chartId": 9,
                "component": OneRowStackGraph
            },
            {
                "chartId": 10,
                "component": SingleBarChart
            }
        ]
    },
    {
        "subtopic": 2,
        "cards": [
            {
                "chartId": 11,
                "component": SingleBarChart, //PlainSingleBarChart
            },
            {
                "chartId": 12,
                "component": TwoColBarChart
            },
            {
                "chartId": 13,
                "component": SingleBarChart, //PlainSingleBarChart
            },
            {
                "chartId": 14,
                "component": TwoColBarChart
            },
            {
                "chartId": 15,
                "component": OneRowTwoColBarChart
            },
            {
                "chartId": 16,
                "component": SingleBarChart
            },
            {
                "chartId": 17,
                "component": ToggleTableView,//DataTable
            },
            {
                "chartId": 18,
                "component": ToggleTableView
            },
            {
                "chartId": 19,
                "component": ToggleTableView
            },
            {
                "chartId": 20,
                "component": ToggleMeanOneRowStackGraph,//MeanOneRowStackGraph
            },
            {
                "chartId": 21,
                "component": MeanOneRowStackGraph
            },
            {
                "chartId": 22,
                "component": MeanOneRowStackGraph
            },
            {
                "chartId": 23,
                "component": MeanOneRowStackGraph
            },
            {
                "chartId": 24,
                "component": OneRowStackGraphLive
            },
            {
                "chartId": 25,
                "component": ToggleTableView
            },
            {
                "chartId": 26,
                "component": OneRowTwoColBarChartNoToggle
            },
            {
                "chartId": 27,
                "component": OneRowTwoColBarChartNoToggle
            },
            {
                "chartId": 28,
                "component": ToggleTableView
            },
            {
                "chartId": 29,
                "component": ToggleTableView
            },
            {
                "chartId": 30,
                "component": ToggleMeanOneRowStackGraph,//MeanOneRowStackGraph
            },
            {
                "chartId": 31,
                "component": MeanOneRowStackGraph
            },
            {
                "chartId": 32,
                "component": ToggleMeanOneRowStackGraph,//ToggleOneRowStackGraphTable,
            },
            {
                "chartId": 33,
                "component": ToggleOneRowStackGraphTable,
            },
            {
                "chartId": 34,
                "component": MeanOneRowStackGraph
            },
            {
                "chartId": 35,
                "component": ToggleMeanOneRowStackGraph,//MeanOneRowStackGraph
            },
            {
                "chartId": 36,
                "component": MeanOneRowStackGraph
            },
            {
                "chartId": 37,
                "component": ToggleTableView
            },
            {
                "chartId": 38,
                "component": ToggleTableView
            },
            {
                "chartId": 63,
                "component": CustomInfo
            },
        ]
    },
    {
        "subtopic": 3,
        "cards": [
            {
                "chartId": 39,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 40,
                "component": PlainSingleBarChart
            },
            {
                "chartId": 41,
                "component": PlainSingleBarChart
            },
            {
                "chartId": 42,
                "component": TogglePlainSingleBarChart
            },
            {
                "chartId": 43,
                "component": TogglePlainSingleBarChart
            },
            {
                "chartId": 44,
                "component": BarWithStackChart
            },
            {
                "chartId": 45,
                "component": ToggleChartTableView,//SingleDataTable
            },
            {
                "chartId": 46,
                "component": BarWithToggeleStackChart
            },
            {
                "chartId": 47,
                "component": TogglePlainSingleBarChart
            },
            {
                "chartId": 51,
                "component": ToggleChartTableView,//MultiToggleTableView
            },
            {
                "chartId": 52,
                "component": BarWithStackChart
            },
            {
                "chartId": 53,
                "component": PlainSingleBarChart
            },
            {
                "chartId": 54,
                "component": TogglePlainSingleBarChart
            },
            {
                "chartId": 55,
                "component": SingleBarChart
            },
            {
                "chartId": 56,
                "component": ToggleChartTableView,//SingleDataTable
            },
            {
                "chartId": 57,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 59,
                "component": MeanOneRowStackGraph,//PlainSingleBarChart
            },
            {
                "chartId": 60,
                "component": SingleBarChart, //PlainSingleBarChart
            },
            {
                "chartId": 61,
                "component": SingleBarChart, //PlainSingleBarChart
            },
            {
                "chartId": 62,
                "component": BarWithToggeleStackChart,//TogglePlainSingleBarChart
            },
            {
                "chartId": 64,
                "component": SingleBarChart
            },
            {
                "chartId": 65,
                "component": SingleBarChart
            },
            {
                "chartId": 66,
                "component": SingleBarChart
            },
            {
                "chartId": 67,
                "component": SingleBarChart
            },
        ]
    },
    {
        "subtopic": 4,
        "cards": [
            {
                "chartId": 68,
                "component": SingleBarChart,
            },
            {
                "chartId": 69,
                "component": SingleBarChart,
            },
            {
                "chartId": 70,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 71,
                "component": SingleBarChart,
            },
            {
                "chartId": 72,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 73,
                "component": SingleBarChart,
            },
            {
                "chartId": 74,
                "component": SingleBarChart,
            },
            {
                "chartId": 75,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 76,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 77,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 78,
                "component": SingleBarChart,
            },
            {
                "chartId": 79,
                "component": SingleBarChart,
            },
            {
                "chartId": 80,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 81,
                "component": ToggleChartTableView,//ToggleTableView
            },
            {
                "chartId": 82,
                "component": ToggleChartTableView,//ToggleTableView
            },
            {
                "chartId": 83,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 84,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 85,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 86,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 87,
                "component": SingleBarChart,
            },
            {
                "chartId": 88,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 89,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 90,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 91,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 92,
                "component": BarWithStackChart,//MeanOneRowStackGraph,
            },
            {
                "chartId": 93,
                "component": SingleBarChart,
            },
            {
                "chartId": 94,
                "component": SingleBarChart,
            },
            {
                "chartId": 95,
                "component": SingleBarChart,
            },
            {
                "chartId": 96,
                "component": BarWithStackChart
            },
            {
                "chartId": 97,
                "component": BarWithStackChart
            },
            {
                "chartId": 98,
                "component": BarWithStackChart
            },
            {
                "chartId": 99,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 100,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 101,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 102,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 103,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 104,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 105,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 106,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 107,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 108,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 109,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 110,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 111,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 112,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 113,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 114,
                "component": SingleBarChart,
            },
            {
                "chartId": 115,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 116,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 117,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 118,
                "component": SingleBarChart,
            },
            {
                "chartId": 119,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 120,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 121,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 176,
                "component": ToggleChartTableView,//ToggleTableView
            },
            {
                "chartId": 177,
                "component": ToggleChartTableView,//ToggleTableView
            },
            {
                "chartId": 178,
                "component": ToggleChartTableView,//ToggleTableView
            },
            {
                "chartId": 381,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 382,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 384,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 385,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 386,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 387,
                "component": ToggleChartTableView,
            },
        ]
    },
    {
        "subtopic": 5,
        "cards": [
            {
                "chartId": 122,
                "component": SingleBarChart,
            },
            {
                "chartId": 123,
                "component": SingleBarChart,
            },
            {
                "chartId": 124,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 125,
                "component": SingleBarChart,
            },
            {
                "chartId": 126,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 127,
                "component": SingleBarChart,
            },
            {
                "chartId": 128,
                "component": SingleBarChart,
            },
            {
                "chartId": 129,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 130,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 131,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 132,
                "component": MeanOneRowStackGraph,//SingleBarChart,
            },
            {
                "chartId": 133,
                "component": MeanOneRowStackGraph, //SingleBarChart,
            },
            {
                "chartId": 134,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 135,
                "component": ToggleChartTableView,//ToggleTableView
            },
            {
                "chartId": 136,
                "component": ToggleChartTableView,//ToggleTableView
            },
            {
                "chartId": 137,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 138,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 139,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 140,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 141,
                "component": SingleBarChart,
            },
            {
                "chartId": 142,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 143,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 144,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 145,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 146,
                "component": BarWithStackChart,//MeanOneRowStackGraph,
            },
            {
                "chartId": 147,
                "component": SingleBarChart,
            },
            {
                "chartId": 148,
                "component": MeanOneRowStackGraph,// SingleBarChart,
            },
            {
                "chartId": 149,
                "component": MeanOneRowStackGraph,//SingleBarChart,
            },
            {
                "chartId": 150,
                "component": BarWithStackChart
            },
            {
                "chartId": 151,
                "component": BarWithStackChart
            },
            {
                "chartId": 152,
                "component": BarWithStackChart
            },
            {
                "chartId": 153,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 154,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 155,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 156,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 157,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 158,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 159,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 160,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 161,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 162,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 163,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 164,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 165,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 166,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 167,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 168,
                "component": SingleBarChart,
            },
            {
                "chartId": 169,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 170,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 171,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 172,
                "component": SingleBarChart,
            },
            {
                "chartId": 173,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 174,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 175,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 179,
                "component": ToggleChartTableView,//ToggleTableView
            },
            {
                "chartId": 180,
                "component": ToggleChartTableView,//ToggleTableView
            },
            {
                "chartId": 181,
                "component": ToggleChartTableView,//ToggleTableView
            },
            {
                "chartId": 388,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 389,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 390,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 391,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 392,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 393,
                "component": ToggleChartTableView,
            },
        ]
    },
    {
        "subtopic": 6,
        "cards": [
            {
                "chartId": 182,
                "component": BarWithStackChart,
            },
            {
                "chartId": 183,
                "component": BarWithStackChart,//SingleBarChart,
            },
            {
                "chartId": 184,
                "component": PlainSingleBarChart,// SingleBarChart,//BarWithStackChart,
            },
            {
                "chartId": 185,
                "component": SingleBarChart,
            },
            {
                "chartId": 186,
                "component": SingleBarChart,//BarWithStackChart,
            },
            {
                "chartId": 187,
                "component": SingleBarChart,
            },
            {
                "chartId": 188,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 189,
                "component": SingleBarChart,
            },
            {
                "chartId": 190,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 191,
                "component": SingleBarChart,
            },
            {
                "chartId": 192,
                "component": SingleBarChart,
            },
            {
                "chartId": 193,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 194,
                "component": ToggleChartTableView, //table
            },
            {
                "chartId": 195,
                "component": SingleBarChart,//PlainSingleBarChart,
            },
        ]
    },
    {
        "subtopic": 7,
        "cards": [
            {
                "chartId": 212,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 213,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 214,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 215,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 216,
                "component": ToggleChartTableView, //table
            },
            {
                "chartId": 217,
                "component": SingleBarChart,
            },
            {
                "chartId": 218,
                "component": SingleBarChart,
            },
            {
                "chartId": 219,
                "component": BarWithStackChart,
            },
            {
                "chartId": 220,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 221,
                "component": SingleBarChart,
            },
            {
                "chartId": 222,
                "component": ToggleChartTableView, //table
            },
            {
                "chartId": 223,
                "component": SingleBarChart,
            },

            {
                "chartId": 224,
                "component": ToggleChartTableView, //table
            },
            {
                "chartId": 225,
                "component": SingleBarChart,
            },
            {
                "chartId": 226,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 227,
                "component": SingleBarChart,
            },

            {
                "chartId": 228,
                "component": SingleBarChart,
            },
            {
                "chartId": 229,
                "component": SingleBarChart,
            },
            {
                "chartId": 230,
                "component": BarWithStackChart,//SingleBarChart,
            },
            {
                "chartId": 231,
                "component": SingleBarChart,
            },
            {
                "chartId": 232,
                "component": SingleBarChart,
            },
            {
                "chartId": 233,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 234,
                "component": SingleBarChart,
            },
            {
                "chartId": 235,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 236,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 237,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 238,
                "component": BarWithStackChart,// MeanOneRowStackGraph,
            },
            {
                "chartId": 239,
                "component": BarWithStackChart,//MeanOneRowStackGraph,
            },
        ]
    },
    {
        "subtopic": 8,
        "cards": [
            {
                "chartId": 242,
                "component": SingleBarChart,
            },
            {
                "chartId": 244,
                "component": SingleBarChart,
            },
            {
                "chartId": 245,
                "component": SingleBarChart,
            },
            {
                "chartId": 246,
                "component": SingleBarChart,
            },
            {
                "chartId": 247,
                "component": BarWithStackChart,//SingleBarChart,
            },
            {
                "chartId": 248,
                "component": PlainSingleBarChart,//SingleBarChart,
            },
            {
                "chartId": 249,
                "component": SingleBarChart,//SingleBarChart,
            },
            {
                "chartId": 250,
                "component": SingleBarChart,
            },
            {
                "chartId": 251,
                "component": BarWithStackChart,
            },
            {
                "chartId": 252,
                "component": PlainSingleBarChart,
            },
            {
                "chartId": 253,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 254,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 255,
                "component": SingleBarChart,
            },
            {
                "chartId": 256,
                "component": SingleBarChart,
            },
        ]
    },
    {
        "subtopic": 9,
        "cards": [
            {
                "chartId": 257,
                "component": BarWithStackChart,
            },
            {
                "chartId": 258,
                "component": SingleBarChart,
            },
            {
                "chartId": 259,
                "component": TogglePlainSingleBarChart, //PlainSingleBarChart,//SingleBarChart,
            },
            {
                "chartId": 260,
                "component": SingleBarChart,
            },
            {
                "chartId": 261,
                "component": ToggleChartTableView,
            },

        ]
    },
    {
        "subtopic": 10,
        "cards": [
            {
                "chartId": 262,
                "component": SingleBarChart,
            },
            {
                "chartId": 263,
                "component": SingleBarChart,
            },
            {
                "chartId": 264,
                "component": BarWithStackChart,
            },
            {
                "chartId": 265,
                "component": BarWithStackChart,
            },
            {
                "chartId": 336,
                "component": BarWithStackChart,
            },
            {
                "chartId": 266,
                "component": BarWithStackChart,
            },
            {
                "chartId": 267,
                "component": SingleBarChart,
            },
            {
                "chartId": 268,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 269,
                "component": BarWithStackChart,
            },
            {
                "chartId": 270,
                "component": SingleBarChart,
            },
        ]
    },
    {
        "subtopic": 13,
        "cards": [
            {
                "chartId": 294,
                "component": BarWithToggeleStackChart,//BarWithStackChart,
            },
            {
                "chartId": 296,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 298,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 299,
                "component": BarWithToggeleStackChart,//BarWithStackChart,
            },
            {
                "chartId": 300,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 301,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 302,
                "component": BarWithToggeleStackChart,//BarWithStackChart,
            },
            {
                "chartId": 303,
                "component": BarWithToggeleStackChart, //BarWithStackChart,
            },
            {
                "chartId": 304,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 305,
                "component": TogglePlainSingleBarChart,// PlainSingleBarChart,//BarWithStackChart,
            },
            {
                "chartId": 306,
                "component": TogglePlainSingleBarChart, //PlainSingleBarChart,//BarWithStackChart,
            },
            {
                "chartId": 307,
                "component": PlainSingleBarChart,//BarWithStackChart,
            },
            {
                "chartId": 308,
                "component": PlainSingleBarChart,//BarWithStackChart,
            },
            {
                "chartId": 309,
                "component": MeanOneRowStackGraph,
            },
        ]
    },
    {
        "subtopic": 12,
        "cards": [
            {
                "chartId": 285,
                "component": MeanOneRowStackGraph, //BarWithStackChart
            },
            {
                "chartId": 286,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 287,
                "component": SingleBarChart,
            },
            {
                "chartId": 288,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 289,
                "component": SingleBarChart,
            },
            {
                "chartId": 290,
                "component": ToggleChartTableView,//TogglePlainSingleBarChart,
            },
            {
                "chartId": 293,
                "component": TogglePlainSingleBarChart,
            },
        ]
    },
    {
        "subtopic": 11,
        "cards": [
            {
                "chartId": 271,
                "component": PlainSingleBarChart, //BarWithStackChart
            },
            {
                "chartId": 272,
                "component": SingleBarChart,
            },
            {
                "chartId": 276,
                "component": SingleBarChart,
            },
            {
                "chartId": 277,
                "component": SingleBarChart, //BarWithStackChart
            },
            {
                "chartId": 278,
                "component": PlainSingleBarChart,
            },
            {
                "chartId": 279,
                "component": PlainSingleBarChart, //BarWithStackChart
            },
            {
                "chartId": 280,
                "component": PlainSingleBarChart, //BarWithStackChart
            },
            {
                "chartId": 281,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 282,
                "component": MeanOneRowStackGraph, //BarWithStackChart
            },
            {
                "chartId": 283,
                "component": SingleBarChart,
            },
            {
                "chartId": 290,
                "component": ToggleChartTableView,//TogglePlainSingleBarChart,
            },
        ]
    },
    {
        "subtopic": 14,
        "cards": [
            {
                "chartId": 310,
                "component": MeanOneRowStackGraph, //BarWithStackChart
            },
            {
                "chartId": 311,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 312,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 313,
                "component": SingleBarChart,
            },
            {
                "chartId": 314,
                "component": SingleBarChart,
            },
            {
                "chartId": 315,
                "component": SingleBarChart,
            },
            {
                "chartId": 316,
                "component": SingleBarChart,
            },
            {
                "chartId": 317,
                "component": SingleBarChart,
            },
            {
                "chartId": 318,
                "component": SingleBarChart,
            },
            {
                "chartId": 319,
                "component": SingleBarChart,
            },
            {
                "chartId": 320,
                "component": SingleBarChart,
            },
            {
                "chartId": 321,
                "component": SingleBarChart,
            },
            {
                "chartId": 322,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 323,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 324,
                "component": SingleBarChart,
            },
            {
                "chartId": 325,
                "component": BarWithStackChart,
            },
            {
                "chartId": 326,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 327,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 328,
                "component": BarWithStackChart,
            },
            {
                "chartId": 329,
                "component": BarWithStackChart,
            },
            {
                "chartId": 330,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 331,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 332,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 333,
                "component": SingleBarChart,
            },
            {
                "chartId": 334,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 335,
                "component": MeanOneRowStackGraph,
            },
        ]
    },
    {
        "subtopic": 16,
        "cards": [
            {
                "chartId": 352,
                "component": ToggleChartTableView, //BarWithStackChart
            },
            {
                "chartId": 353,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 354,
                "component": SingleBarChart,
            },
            {
                "chartId": 355,
                "component": TogglePlainSingleBarChart,//PlainSingleBarChart,
            },
            {
                "chartId": 356,
                "component": SingleBarChart,
            },
            {
                "chartId": 357,
                "component": SingleBarChart,
            },
            {
                "chartId": 358,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 359,
                "component": SingleBarChart,//BarWithStackChart
            },
            {
                "chartId": 360,
                "component": ToggleChartTableView,//BarWithStackChart
            },
            {
                "chartId": 361,
                "component": MeanOneRowStackGraph,//BarWithStackChart
            },
            {
                "chartId": 362,
                "component": ToggleMeanOneRowStackGraph,//MeanOneRowStackGraph,//BarWithStackChart
            },
            {
                "chartId": 363,
                "component": MeanOneRowStackGraph,//BarWithStackChart
            },
            {
                "chartId": 364,
                "component": BarWithToggeleStackChart,//BarWithStackChart,
            },
            {
                "chartId": 664,
                "component": BarWithToggeleStackChart,//BarWithStackChart,
            },
            {
                "chartId": 365,
                "component": BarWithStackChart,
            },
            {
                "chartId": 366,
                "component": BarWithToggeleStackChart,// BarWithStackChart,
            },
            {
                "chartId": 665,
                "component": BarWithToggeleStackChart,// BarWithStackChart,
            },
            {
                "chartId": 367,
                "component": BarWithStackChart,
            },
            {
                "chartId": 368,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 369,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 370,
                "component": MeanOneRowStackGraph,//BarWithStackChart,
            },
            {
                "chartId": 371,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 372,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 373,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 374,
                "component": BarWithStackChart,
            },
            {
                "chartId": 375,
                "component": SingleBarChart,
            },
            {
                "chartId": 376,
                "component": MeanOneRowStackGraph,//BarWithStackChart,
            },
            {
                "chartId": 377,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 378,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 379,
                "component": MeanOneRowStackGraph,
            },
        ]
    },
    {
        "subtopic": 15,
        "cards": [
            {
                "chartId": 337,
                "component": MeanOneRowStackGraph, //BarWithStackChart
            },
            {
                "chartId": 338,
                "component": PlainSingleBarChart,
            },
            {
                "chartId": 339,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 340,
                "component": PlainSingleBarChart,
            },
            {
                "chartId": 341,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 342,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 343,
                "component": BarWithStackChart,//PlainSingleBarChart,
            },
            {
                "chartId": 344,
                "component": SingleBarChart,
            },
            {
                "chartId": 345,
                "component": SingleBarChart,
            },
            {
                "chartId": 346,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 347,
                "component": SingleBarChartWithLives,//SingleBarChart,
            },
            {
                "chartId": 348,
                "component": SingleBarChartWithLives,//SingleBarChart,
            },
            {
                "chartId": 349,
                "component": PlainSingleBarChart,
            },
            {
                "chartId": 350,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 351,
                "component": SingleBarChart,
            },
            {
                "chartId": 380,
                "component": MeanOneRowStackGraph,
            },
        ]
    },
    {
        "subtopic": 17,
        "cards": [
            {
                "chartId": 394,
                "component": SingleBarChart, //MeanOneRowStackGraph, //
            },
            {
                "chartId": 395,
                "component": BarWithStackChart, //PlainSingleBarChart
            },
            {
                "chartId": 396,
                "component": BarWithStackChart,
            },
            {
                "chartId": 397,
                "component": SingleBarChart,
            },
            {
                "chartId": 398,
                "component": BarWithStackChart,
            },
            {
                "chartId": 399,
                "component": BarWithStackChart,
            },
            {
                "chartId": 400,
                "component": SingleBarChart,
            },
            {
                "chartId": 401,
                "component": BarWithStackChart,
            },
            {
                "chartId": 402,
                "component": BarWithStackChart,
            },
            {
                "chartId": 403,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 404,
                "component": SingleBarChart,
            },
            {
                "chartId": 405,
                "component": PlainSingleBarChart,//BarWithStackChart,
            },
            {
                "chartId": 406,
                "component": PlainSingleBarChart, //BarWithStackChart,
            },
            {
                "chartId": 407,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 408,
                "component": CustomInfo,
            },
            {
                "chartId": 409,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 410,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 411,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 412,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 413,
                "component": CustomInfo,
            },
            {
                "chartId": 414,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 415,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 416,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 417,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 418,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 419,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 420,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 421,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 422,
                "component": SingleBarChart,
            },
            {
                "chartId": 423,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 424,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 425,
                "component": SingleBarChart,//PlainSingleBarChart,
            },
        ]
    },
    {
        "subtopic": 18,
        "cards": [
            {
                "chartId": 426,
                "component": MeanOneRowStackGraph, // BarWithStackChart //
            },
            {
                "chartId": 427,
                "component": MeanOneRowStackGraph, // BarWithStackChart //
            },
            {
                "chartId": 428,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 429,
                "component": ToggleChartTableView, // TogglePlainSingleBarChart //
            },
            {
                "chartId": 430,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 431,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 435,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 441,
                "component": ToggleChartTableView, // TogglePlainSingleBarChart //
            },
            {
                "chartId": 442,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 443,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 444,
                "component": SingleBarChart,
            },
            {
                "chartId": 445,
                "component": SingleBarChart, //PlainSingleBarChart
            },
            {
                "chartId": 446,
                "component": SingleBarChart, //PlainSingleBarChart
            },
            {
                "chartId": 447,
                "component": SingleBarChart, //PlainSingleBarChart
            },
            {
                "chartId": 448,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 449,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 450,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 451,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 452,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 453,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 454,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 455,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 456,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 457,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 458,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 459,
                "component": SingleBarChart,
            },
            {
                "chartId": 460,
                "component": SingleBarChart,
            },
            {
                "chartId": 461,
                "component": SingleBarChart,
            },
            {
                "chartId": 462,
                "component": SingleBarChart,
            },
            {
                "chartId": 463,
                "component": SingleBarChart,
            },
            {
                "chartId": 464,
                "component": SingleBarChart,
            },
            {
                "chartId": 465,
                "component": SingleBarChart,
            },
            {
                "chartId": 466,
                "component": SingleBarChart,
            },
            {
                "chartId": 467,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 468,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 469,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 470,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 471,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 472,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 473,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 474,
                "component": ToggleChartTableView,
            },
        ]
    },
    {
        "subtopic": 19,
        "cards": [
            {
                "chartId": 433,
                "component": MeanOneRowStackGraph, //MeanOneRowStackGraph
            },
            {
                "chartId": 434,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 438,
                "component": TogglePlainSingleBarChart,
            },
            {
                "chartId": 439,
                "component": BarWithStackChart, //PlainSingleBarChart
            },
            {
                "chartId": 475,
                "component": BarWithStackChart,
            },
            {
                "chartId": 476,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 477,
                "component": BarWithStackChart,
            },
            {
                "chartId": 478,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 479,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 480,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 481,
                "component": BarWithToggeleStackChart,
            },
            {
                "chartId": 482,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 483,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 397,
                "component": SingleBarChart,
            },
        ]
    },
    {
        "subtopic": 21,
        "cards": [
            {
                "chartId": 484,
                "component": VerticlePlainSingleBarChart,
            },
            {
                "chartId": 485,
                "component": VerticlePlainSingleBarChart,
            },
            {
                "chartId": 486,
                "component": VerticleTwoViewPlainSingleBarChart,
            },
            {
                "chartId": 487,
                "component": VerticleTwoViewPlainSingleBarChart,
            },
            {
                "chartId": 488,
                "component": VerticleTwoViewPlainSingleBarChart,
            },
            {
                "chartId": 489,
                "component": VerticlePlainSingleBarChart,
            },
            {
                "chartId": 490,
                "component": VerticlePlainSingleBarChart,//SingleBarChart, //MeanOneRowStackGraph
            },
            {
                "chartId": 491,
                "component": VerticlePlainSingleBarChart,
            },
            {
                "chartId": 492,
                "component": VerticlePlainSingleBarChart,
            },
            {
                "chartId": 493,
                "component": BarWithStackChart,//PlainSingleBarChart,
            },
        ]
    },
    {
        "subtopic": 22,
        "cards": [
            {
                "chartId": 494,
                "component": VerticleTwoViewPlainSingleBarChart,
            },
            {
                "chartId": 495,
                "component": VerticlePlainSingleBarChart,
            },
            {
                "chartId": 497,
                "component": VerticlePlainSingleBarChart,
            },
            {
                "chartId": 498,
                "component": SingleBarChart, //MeanOneRowStackGraph
            },
        ]
    },
    {
        "subtopic": 23,
        "cards": [
            {
                "chartId": 499,
                "component": SingleBarChart, //MeanOneRowStackGraph
            },
        ]
    },
    {
        "subtopic": 24,
        "cards": [
            {
                "chartId": 502,
                "component": SingleBarChart,
            },
            {
                "chartId": 503,
                "component": SingleBarChart,
            },
            {
                "chartId": 504,
                "component": SingleBarChart,
            },
            {
                "chartId": 505,
                "component": BarWithStackChart,
            },
            {
                "chartId": 506,
                "component": BarWithStackChart, //MeanOneRowStackGraph
            },
            {
                "chartId": 507,
                "component": BarWithStackChart, //MeanOneRowStackGraph
            },
            {
                "chartId": 508,
                "component": PlainSingleBarChart, //MeanOneRowStackGraph
            },
        ]
    },
    {
        "subtopic": 33,
        "cards": [
            {
                "chartId": 532,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 533,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 534,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 536,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 537,
                "component": BarWithStackChart,
            },
            {
                "chartId": 538,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 541,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 542,
                "component": BarWithStackChart,
            },
            {
                "chartId": 543,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 544,
                "component": SingleBarChart,
            },
            {
                "chartId": 545,
                "component": SingleBarChart,
            },
            {
                "chartId": 546,
                "component": BarWithStackChart,
            },
            {
                "chartId": 547,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 548,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 549,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 551,
                "component": SingleBarChart,
            },
        ]
    },
    {
        "subtopic": 34,
        "cards": [
            {
                "chartId": 552,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 553,
                "component": SingleBarChart,
            },
            {
                "chartId": 554,
                "component": SingleBarChart,
            },
            {
                "chartId": 555,
                "component": SingleBarChart,
            },
            {
                "chartId": 556,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 557,
                "component": SingleBarChart,
            },
            {
                "chartId": 558,
                "component": SingleBarChart,
            },
            {
                "chartId": 559,
                "component": SingleBarChart,
            },
            {
                "chartId": 560,
                "component": SingleBarChart,
            },
            {
                "chartId": 561,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 562,
                "component": SingleBarChart,
            },
        ]
    },
    {
        "subtopic": 35,
        "cards": [
            {
                "chartId": 563,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 564,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 565,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 566,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 567,
                "component": BarWithStackChart,
            },
            {
                "chartId": 568,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 569,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 570,
                "component": BarWithStackChart,
            },
            {
                "chartId": 571,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 572,
                "component": SingleBarChart,
            },
            {
                "chartId": 573,
                "component": SingleBarChart,
            },
            {
                "chartId": 574,
                "component": BarWithStackChart,
            },
            {
                "chartId": 575,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 576,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 577,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 578,
                "component": SingleBarChart,
            },
        ]
    },
    {
        "subtopic": 36,
        "cards": [
            {
                "chartId": 579,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 580,
                "component": SingleBarChart,
            },
            {
                "chartId": 581,
                "component": SingleBarChart,
            },
            {
                "chartId": 582,
                "component": SingleBarChart,
            },
            {
                "chartId": 583,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 584,
                "component": SingleBarChart,
            },
            {
                "chartId": 585,
                "component": SingleBarChart,
            },
            {
                "chartId": 586,
                "component": SingleBarChart,
            },
            {
                "chartId": 587,
                "component": SingleBarChart,
            },
            {
                "chartId": 588,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 589,
                "component": SingleBarChart,
            },
        ]
    },
    {
        "subtopic": 37,
        "cards": [
            {
                "chartId": 603,
                "component": BarWithStackChart,
            },
            {
                "chartId": 597,
                "component": SingleBarChart,
            },
            {
                "chartId": 598,
                "component": BarWithStackChart,
            },
            {
                "chartId": 599,
                "component": SingleBarChart,
            },
            {
                "chartId": 600,
                "component": SingleBarChart,
            },
            {
                "chartId": 601,
                "component": SingleBarChart,
            },
            {
                "chartId": 602,
                "component": SingleBarChart,
            },
        ]
    },
    {
        "subtopic": 38,
        "cards": [
            {
                "chartId": 604,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 605,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 606,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 607,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 608,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 609,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 610,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 611,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 612,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 613,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 614,
                "component": SingleBarChart,
            },
            {
                "chartId": 615,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 616,
                "component": SingleBarChart,
            },
            {
                "chartId": 617,
                "component": SingleBarChart,
            },
            {
                "chartId": 618,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 619,
                "component": SingleBarChart,
            },
            {
                "chartId": 620,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 621,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 622,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 623,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 624,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 625,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 626,
                "component": SingleBarChart,
            },
            {
                "chartId": 627,
                "component": SingleBarChart,
            },
            {
                "chartId": 628,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 629,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 630,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 631,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 632,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 633,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 634,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 635,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 636,
                "component": SingleBarChart,
            },
            {
                "chartId": 637,
                "component": SingleBarChart,
            },
            {
                "chartId": 638,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 639,
                "component": SingleBarChart,
            },
            {
                "chartId": 640,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 641,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 642,
                "component": MeanOneRowStackGraph,
            },
        ]
    },
    {
        "subtopic": 39,
        "cards": [
            {
                "chartId": 643,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 644,
                "component": SingleBarChart,
            },
            {
                "chartId": 645,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 646,
                "component": SingleBarChart,
            },
            {
                "chartId": 647,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 648,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 649,
                "component": SingleBarChart,
            },
            {
                "chartId": 650,
                "component": ToggleChartTableView,
            },
            {
                "chartId": 651,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 652,
                "component": BarWithStackChart,
            },
            {
                "chartId": 653,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 654,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 655,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 656,
                "component": SingleBarChart,
            },
            {
                "chartId": 657,
                "component": SingleBarChart,
            },
            {
                "chartId": 658,
                "component": SingleBarChart,
            },
            {
                "chartId": 659,
                "component": SingleBarChart,
            },
            {
                "chartId": 660,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 661,
                "component": BarWithStackChart,
            },
            {
                "chartId": 662,
                "component": SingleBarChart,
            },
            {
                "chartId": 663,
                "component": SingleBarChart,
            },
        ]
    },
    {
        "subtopic": 40,
        "cards": [
            {
                "chartId": 666,
                "component": MeanOneRowStackGraph,
            },
            {
                "chartId": 667,
                "component": SingleBarChart,
            },
            {
                "chartId": 679,
                "component": PlainSingleBarChart,
            },
            {
                "chartId": 689,
                "component": BarWithStackChart,
            },
            {
                "chartId": 669,
                "component": SingleBarChart,
            },
            {
                "chartId": 670,
                "component": SingleBarChart,
            },
            {
                "chartId": 671,
                "component": SingleBarChart,
            },
            {
                "chartId": 673,
                "component": SingleBarChart,
            },
            {
                "chartId": 674,
                "component": SingleBarChart,
            },
            {
                "chartId": 675,
                "component": SingleBarChart,
            },
            {
                "chartId": 676,
                "component": SingleBarChart,
            },
            {
                "chartId": 677,
                "component": SingleBarChart,
            },
            {
                "chartId": 678,
                "component": SingleBarChart,
            },
        ]
    },
    {
        "subtopic": 41,
        "cards": [
            {
                "chartId": 680,
                "component": SingleBarChart,
            },
            {
                "chartId": 681,
                "component": SingleBarChart,
            },
            {
                "chartId": 682,
                "component": BarWithStackChart,
            },
            {
                "chartId": 683,
                "component": BarWithStackChart,
            },
            {
                "chartId": 684,
                "component": BarWithStackChart,
            },
            {
                "chartId": 685,
                "component": SingleBarChart,
            },
            {
                "chartId": 686,
                "component": SingleBarChart,
            },
        ]
    },
]